import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import {uploadNewImage} from '../settings/storage';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const Input = styled('input')({
    display: 'none',
});

export default function UploadFile({idKey}){

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = async (event) => {
        const fileUploaded = event.target.files[0];
        const fileUrl = await uploadNewImage(fileUploaded);
        document.getElementById("picture").value = fileUrl;
    };

    return (
        <div style={{display:'flex', justifyContent:'flex-start'}}>
        <label htmlFor="contained-button-file">
            <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleChange}/>
            <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                Upload File
            </Button>
        </label>
        <TextField id={idKey} label={'File Link'} style={{marginLeft:'2.5vw', flexGrow: 1}} />
        </div>);
}