import './App.css';
import HeaderBar from './components/headerbar';
import Gallery from './components/gallery'

function App() {
  return (
    <div className="App">
      <HeaderBar />
      <Gallery />
    </div>
  );
}

export default App;
