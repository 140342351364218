import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Slide from '@mui/material/Slide';
import { Button } from '@mui/material';
import NewItemFromContent from './newItemFormContent';
import Forms from '../settings/forms.json';
import {createData} from '../settings/db';
import { useSnackbar } from 'notistack';

// Settings
import Strings from '../settings/strings.json';
import Texts from '../settings/texts.json';
// import Forms from '../settings/forms.json';

//const
const language = "en";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function NewItemFrom({itemType, open, handleClose, title}){
    const { enqueueSnackbar } = useSnackbar();

    const newAlert = (message, type) => {
        enqueueSnackbar(message, {variant: type});
    };


    const postNewItem = async () => {
        const newItem = {}
        try{
            Object.keys(Forms[itemType]).forEach((key) => {
                if(document.getElementById(key).value === ''){
                    throw Error(`Missing Field ${key}`);
                }
                newItem[key] = document.getElementById(key).value;
            });
            await createData(itemType, newItem);
            newAlert(`Succesded in adding new ${itemType}: ${newItem.name}`, 'success')
        } catch(err){
            newAlert(`Failed Because: ${err.message}`, 'error')
        }
        handleClose();
    };

    return(
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
        <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        >
        <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        {title}
        </Typography>
        </Toolbar>
        </AppBar>
        <DialogContent>
        <DialogContentText>
        {Texts[language]["forms"][itemType]}
        </DialogContentText>
        <br />
        <NewItemFromContent itemType={itemType} />
        </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>{Strings[language]["cancel"]}</Button>
                <Button variant="contained"  onClick={postNewItem} endIcon={<CheckCircleIcon />}>
                    {Strings[language]["upload"]}
                </Button>
            </DialogActions>
        </Dialog>
    );
}