import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { collection, getDocs, doc, setDoc } from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: "AIzaSyDYMZ-YFYqnJUZ0ahTdGkeT9JJ2ifHwt5Y",
    authDomain: "shlinger-255.firebaseapp.com",
    projectId: "shlinger-255",
    storageBucket: "shlinger-255.appspot.com",
    messagingSenderId: "732075980418",
    appId: "1:732075980418:web:80bd33ab75e77a29a4269b",
    measurementId: "G-MMN4DB3KVS"
};

initializeApp(firebaseConfig);

const db = getFirestore();

export async function getData(type){
    let data = [];
    const querySnapshot = await getDocs(collection(db, type));
    querySnapshot.forEach((doc) => {
        data.push(doc.data());
    });
    return data;
}

export async function createData(type, data){
    await setDoc(doc(db, type, data.name), data);
}

export default db;