import * as React from 'react';
import Button from '@mui/material/Button';
import Strings from '../settings/strings.json';
import NewItemFrom from './newItemForm';

const language = "en";

export default function AddItem({itemType}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let title = `${Strings[language]["add"]} ${Strings[language][itemType]}`;

  return (
    <div>
      <Button variant="contained" size="large" onClick={handleClickOpen}>
        {title}
      </Button>
      <NewItemFrom itemType={itemType} open={open} handleClose={handleClose} title={title}/>
    </div>
  );
}
