import * as React from 'react';
import '../App.css';
import HeaderBar from '../components/headerbar';
import { Stack } from '@mui/material';
import AddItem from '../components/additem';
import { SnackbarProvider } from 'notistack';

function Admin() {
  return (
    <SnackbarProvider>
      <HeaderBar />
      <Stack
        style={{marginTop: "5vh", height: "80vh"}}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={5}
      >
        <AddItem itemType="record" />
        <AddItem itemType="disc" />
        <AddItem itemType="movie" />
      </Stack>
    </SnackbarProvider>
  );
}

export default Admin;
