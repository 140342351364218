import '../App.css';
import MainBlock from '../components/mainBlock';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";


function About() {
  let navigate = useNavigate();

  return (
    <div className="App">
      <MainBlock 
      image={"https://firebasestorage.googleapis.com/v0/b/shlinger-255.appspot.com/o/IMG-20200711-WA0032.jpg?alt=media&token=77374c44-97df-468f-b440-69c5d277c2c1"}
      imageText="The Collection"
      title="Grandpa Yossi's Collection"
      description="all the records grenpa yossi collected through the years presented in a site to his memory"
      />
      <Button onClick={() => navigate("/home") } variant="contained" size="large" style={{position:'absolute', right: '35vw', bottom: '30vh'}}>To Collection</Button>
    </div>
  );
}

export default About;
