import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Enums from '../settings/enums.json';

export default function FromSelect({selectKey}) {
  const [data, setData] = React.useState('');

  const handleChange = (event) => {
    setData(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel value={data} id={"label" + selectKey}>{selectKey}</InputLabel>
        <Select
          labelId={selectKey}
          id={"id" + selectKey}
          value={data}
          label={selectKey}
          onChange={handleChange}
        >
            {
                Enums[selectKey].map((element) => {
                    return <MenuItem value={element}>{element}</MenuItem>;
                })
            }
        </Select>
      </FormControl>
      <input id={selectKey} value={data} style={{display:'none'}} />
    </Box>
  );
}
