import * as React from 'react';
import Forms from '../settings/forms.json';
import TextField from '@mui/material/TextField';
import FromSelect from './formSelect';
import UploadFile from './uploadFile';


export default function NewItemFromContent({itemType}){



    return (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around', height:'70vh'}}>
        {
            Object.entries(Forms[itemType]).map((element) => {
                const [key, value] = element;
                switch(value){
                    case "string":
                        return <TextField id={key} label={key} />;
                    case "number":
                        return <TextField id={key} label={key} />;
                    case "enum":
                        return (
                            <FromSelect id="test" selectKey={key}></FromSelect>
                        );
                    case "file":
                        return <UploadFile idKey={key}/>;
                    default:
                        return <h2>Work In Progress</h2> 
                }
            })   
        }
        </div> 
        )
}
