import * as React from 'react';
import Record from './record';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { getData } from '../settings/db';

export default function Gallery() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData(){
        let records = await getData("record");
        setData(records);
    }

    fetchData();
  }, []);

    return (
        <Box sx={{ width: '100', }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ width: '100vw', marginTop: "1.5vh", display: "flex", justifyContent: "center"}}>
            {
                data.map((info) => {
                        return (
                            <Grid item>
                                <Record info={info} />
                            </Grid>
                        );
                })
            }
        </Grid>
        </Box>
    );
};