import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Strings from '../settings/strings.json';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from "react-router-dom";

const drawerWidth = 250;
const lenguge = "en";

export default function Headbar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(newState);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Shlinger Collection
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <Link to="/home">
            <ListItem button key={"home"} path="/home">
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={Strings[lenguge]["home"]} />
            </ListItem>
          </Link>
          <Link to="/admin">
            <ListItem button key={"admin"} onClick="/admin">
              <ListItemIcon><AddCircleIcon /></ListItemIcon>
              <ListItemText primary={Strings[lenguge]["admin"]} />
            </ListItem>
          </Link>
          <Link to="/" >
            <ListItem button key={"about"} to="/">
              <ListItemIcon><InfoIcon /></ListItemIcon>
              <ListItemText primary={Strings[lenguge]["about"]} />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </Box>
  );
}