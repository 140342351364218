import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDYMZ-YFYqnJUZ0ahTdGkeT9JJ2ifHwt5Y",
    authDomain: "shlinger-255.firebaseapp.com",
    projectId: "shlinger-255",
    storageBucket: "shlinger-255.appspot.com",
    messagingSenderId: "732075980418",
    appId: "1:732075980418:web:80bd33ab75e77a29a4269b",
    measurementId: "G-MMN4DB3KVS"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

export async function uploadNewImage(file){
    const filesRef = ref(storage, 'images/' + file.name);
    await uploadBytes(filesRef, file);
    return `https://firebasestorage.googleapis.com/v0/b/shlinger-255.appspot.com/o/images%2F${file.name}?alt=media&token=56297893-f341-443d-8345-763cb451f17c`;
}

